
export const getMasterPlanBody = async (URL) =>{
    try{
        const response = await fetch(URL)
        return response?.text()
    }
    catch (e) {
        return e;
    }
}

export const executeScripts = html => {
    const scripts = Array.from(new DOMParser().parseFromString(html, 'text/html').querySelectorAll('script'));
    const externalScripts = scripts.filter(script => script.src);
  
    let loadedScripts = 0;
    const onScriptLoad = () => {
      loadedScripts++;
      if (loadedScripts === externalScripts.length) {
        const inlineScripts = scripts.filter(script => !script.src);
        inlineScripts.forEach(script => {
          const scriptElement = document.createElement('script');
          scriptElement.innerHTML = script.innerHTML;
          document.body.appendChild(scriptElement);
        });
      }
    };
  
    externalScripts.forEach(script => {
      const scriptElement = document.createElement('script');
      scriptElement.src = script.src;
      scriptElement.onload = onScriptLoad;
      document.body.appendChild(scriptElement);
    });
  };
