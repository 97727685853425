import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import HomeSearch from './HomeSearch/HomeSearch'
import { handleLogInViaWhatsapp, isUserLoggedIn } from '@/utils/utils'
import useTranslation from 'next-translate/useTranslation'
import { executeScripts } from 'services/SRenderService'
import Head from 'next/head'
import ReactHtmlParser from 'react-html-parser'
import { useAppContext } from 'context/AppContext'
import AuthenticatorLoaderPopup from '../Common/AuthenticatorLoader'
import ToastMessage from '@/hoc/components/SuccessScreen/Toast/toast'
import { getRequirements } from '../ShareYourRequirement/MatchMakerUtils'

const Home = (props) => {
  const { cmsData,isMobileSSR=false } = props
  const router = useRouter()
  const {t, lang } = useTranslation('translations')
  const { state } = useAppContext()
  const { searchReducer } = state
  const [isWhatsappLoginLoading, setIsWhatsappLoginLoading] = useState(false);
  const [toast,setToast] = useState(false)
  const [searchLoader, setSearchLoader] = useState(false)

  const handleSearch = (cityInfo, transactionType,districtAndPropertyTypeObject=false) => {
    // to get transactionType from home @sourabh_c
    if(cityInfo && districtAndPropertyTypeObject){
      const cityVal = cityInfo.cityId || cityInfo.city_id ? `&cityId=${cityInfo.cityId || cityInfo.city_id}` : ''
      const districtVal = districtAndPropertyTypeObject?.districtValue?.length!==0 ? `&districtId=` + String(districtAndPropertyTypeObject?.districtValue?.map((val)=>val?.districtId)) : ''
      const propertyTypeFilter = districtAndPropertyTypeObject?.propertyType.find((val)=>val?.isSelected) ? districtAndPropertyTypeObject?.propertyType.filter((val)=>val?.isSelected) :'';
      const selectedPropertyType = propertyTypeFilter ? `&propertyTypeData=` + String(propertyTypeFilter?.map((val)=>val?.value)) :''
      router.push(
        `/${transactionType}/search?propertyFor=${transactionType}&countryId=1${cityVal}${districtVal}&type=${searchReducer?.propertyType}${selectedPropertyType}`,
        undefined,
        undefined,
      )
    }
   else if(cityInfo){
      setSearchLoader && setSearchLoader(true)
      const cityVal = cityInfo.cityId || cityInfo.city_id ? `&cityId=${cityInfo.cityId || cityInfo.city_id}` : ''
      const districtVal = cityInfo.districtId ? `&districtId=` + cityInfo.districtId : ''
      if (transactionType) {
        router.push(
          `/${transactionType}/search?propertyFor=${transactionType}&countryId=1${cityVal}${districtVal}&type=${searchReducer?.propertyType}
        `,
          undefined,
          undefined,
        )
      } else {
        router.push(
          `/sale/search?propertyFor=sale&countryId=1${cityVal}${districtVal}&type=${searchReducer?.propertyType}`,
          undefined,
          undefined,
        )
      }
    }
  }

  const handleWhatsappAutoLogin = async () => {
    setIsWhatsappLoginLoading(true)
    await handleLogInViaWhatsapp(lang)
    if (isUserLoggedIn()){
      setIsWhatsappLoginLoading(false)
    }
    else{
      setIsWhatsappLoginLoading(false)
      setToast(true)

    }
  }

  useEffect(() => {
    if(!isUserLoggedIn() && localStorage.getItem('whatsapp-auth-token')) {
      handleWhatsappAutoLogin();
    }
    setSearchLoader && setSearchLoader(false)

    //handling click on home matchmaker banner cta
    const onClickMatchmakerHandler = () => {
      if(isUserLoggedIn()) {
        getRequirements(lang, ()=>router.push('/user/myrequirements?source=HomeBanner'), ()=>router.push('/post-requirement'))
      } else {
      router.push('/post-requirement')
      }
    }
    window.onClickMatchmakerBanner = () => onClickMatchmakerHandler()
    return () => {
      window.onClickMatchmakerBanner = null
    }
  }, [])

  useEffect(() => {
    executeScripts(cmsData?.head_content?._html)
  }, [cmsData?.head_content?._html])

  return (
    <div>
      {cmsData?.head_content && (
        <Head>{ReactHtmlParser(cmsData?.head_content?._html ?? cmsData?.head_content?._html)}</Head>
      )}
      <HomeSearch
        onSearch={(cityInfo, transactionType,districtAndPropertyTypeObject) => handleSearch(cityInfo, transactionType,districtAndPropertyTypeObject)}
        bannerData={cmsData?.body_content?.banner}
        mainHeading={cmsData?.body_content?.mainHeading}
        searchLoader={searchLoader}
        setSearchLoader={setSearchLoader}
        isMobileSSR={isMobileSSR}
      />
      <div>{ReactHtmlParser(cmsData?.body_content?._html ?? cmsData)}</div>
      {isWhatsappLoginLoading ? <AuthenticatorLoaderPopup t={t}/> : null}
      {toast&&<ToastMessage toast={toast} text={t('common.LOGIN_FAIL')} iconClass={'error'} positionClass={['position']} extraClass={['alertInRed']} setToast={setToast}/>}
    </div>
  )
}

export default Home
