import Head from "next/head";
import { ContactNumber } from "@/utils/utils";

const CanonicalLink = ({canonical_tag, alternate_ar, alternate_en, x_default, lang}) => 
(
    <>
      <link
        rel='canonical'
        href={`${process.env.NEXT_PUBLIC_BASE_URL}${
          lang == "en" ? `/en${canonical_tag}` : canonical_tag
        }`}
      />
      <link
        rel='alternate'
        hrefLang='ar'
        href={`${process.env.NEXT_PUBLIC_BASE_URL}${alternate_ar}`}
      />
      <link
        rel='alternate'
        hrefLang='en'
        href={`${process.env.NEXT_PUBLIC_BASE_URL}/en${alternate_en}`}
      />
      <link
        rel='alternate'
        hrefLang='x-default'
        href={`${process.env.NEXT_PUBLIC_BASE_URL}${x_default}`}
      />
    </>
)

export const SchemaMarkup = (
  canonical_tag,
  alternate_ar,
  alternate_en,
  x_default,
  t,
  lang,
  props = {},
) => {
  const {
    schemaType = "",
    breadcrumbsList = [],
    propertyDetails = {},
    propertyImage = "",
  } = props;

  const BreadcrumbsMarkupSchema = () => ({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbsList.map(({ name, item }, index) => ({
        "@type": "ListItem",
        position: index + 1,
        name,
        item: `${process.env.NEXT_PUBLIC_BASE_URL}${item}`,
    })),
  });

  const schemaCheck = schemaType == "BreadcrumbList" || schemaType == "DetailPage"
    ? JSON.stringify(BreadcrumbsMarkupSchema())
    : "";
  
  const HomeSchema = () => ({
      "@context": "https://schema.org",
      "@type": "Corporation",
      name: t("schema.name"),
      url: `${process.env.NEXT_PUBLIC_BASE_URL}${
        lang == "en" ? `/en${alternate_en}` : alternate_ar
      }`,
      telephone: ContactNumber,
      logo: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/wasalt-logo${lang == 'en' ? '' : '-ar'}.svg?v1`,
      image: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/wasalt-logo${lang == 'en' ? '' : '-ar'}.svg?v1`,
      address: {
        "@type": "PostalAddress",
        addressLocality: t("schema.addressLocality"),
        addressRegion: t("schema.addressRegion"),
        sameAs: [
          "https://www.facebook.com/WasaltOfficial",
          "https://twitter.com/WasaltOfficial",
          "https://instagram.com/wasaltofficial",
          "https://www.youtube.com/channel/UCwV9YXTxwCNu-XhmsmWKJFA/featured",
          "https://www.linkedin.com/company/wasaltofficial",
        ],
      },
  });

  const SingleFamiltResidence = () => {
    const {
      propertyInfo: {
        title,
        description,
        salePrice,
        expectedRent,
        conversionUnit,
        expectedRentType,
        country,
        city,
        territory,
        zone,
      },
      attributes,
      propertyOwner: { phone },
    } = propertyDetails;
    const residenceDetail = {
      "@context": "https://schema.org",
      "@type": "SingleFamilyResidence",
      image: propertyImage,
    };
    if (title) {
      residenceDetail["name"] = title.split(",")[0];
      residenceDetail["leaseLength"] = {
        "@type": "QuantitativeValue",
        unitText: title.split(",")[0],
      };
    }
    if (description) residenceDetail["description"] = description;
    const bedrooms = attributes?.find(({ key }) => key == "noOfBedrooms")?.value?.toString();
    if (bedrooms) {
      residenceDetail["numberOfRooms"] = bedrooms;
      residenceDetail["numberOfBedrooms"] = bedrooms;
    }
    const bathrooms = attributes?.find(
      ({ key }) => key == "noOfBathrooms"
    )?.value?.toString();
    if (bedrooms) residenceDetail["numberOfBathroomsTotal"] = bathrooms;
    if (country || city || territory || zone) {
      residenceDetail["address"] = { "@type": "PostalAddress" };
      if (country) residenceDetail.address["addressCountry"] = country;
      if (city) residenceDetail.address["addressLocality"] = city;
      if (territory) residenceDetail.address["addressRegion"] = territory;
      if (zone) residenceDetail.address["streetAddress"] = zone;
    }
    if (salePrice || expectedRent) {
      residenceDetail["offers"] = [
        {
          "@type": "Offer",
          price: salePrice || expectedRent,
          priceCurrency: salePrice
            ? conversionUnit
            : `${conversionUnit} ${expectedRentType}`,
        },
      ];
    }
    if (phone) residenceDetail["telephone"] = phone;
    return residenceDetail;
  };
  return (
    <Head>
      <CanonicalLink canonical_tag={canonical_tag} alternate_ar={alternate_ar} alternate_en={alternate_en} x_default={x_default} lang={lang} />
      {schemaType && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html:
              schemaType == "Home"
                ? JSON.stringify(HomeSchema())
                : schemaCheck,
          }}
        />
      )}
      {schemaType == "DetailPage" && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(SingleFamiltResidence()),
          }}
        />
      )}
    </Head>
  );
};
