import React from 'react'
import { useRouter } from 'next/router'
import { SchemaMarkup } from '@/utils/schemaMarkup'
import { getAssetsBaseURL } from '@/utils/utils'

function SchemaMarkupComponent(props) {
  const router = useRouter()
  const {
    t,
    lang,
    metaData,
    params,
    compoundDetails,
    breadcrumbsLinks,
    compoundBreadcrumbDetails,
    galleryDetails,
    propertyDetailsV3,
  } = props
  const queryString = router?.query?.page ? `?page=${router?.query?.page}` : ''
  const ArabicLangCheck = lang == 'ar' ? metaData?.slug : metaData?.alternateSlug
  const EnglishLangCheck = lang == 'en' ? metaData?.slug : metaData?.alternateSlug
  const englishSlugProperty =
    (lang == 'en' ? propertyDetailsV3?.propertyInfo?.slug : propertyDetailsV3?.propertyInfo?.alternateSlug) || ''
  const arabicSlugProperty =
    (lang == 'ar' ? propertyDetailsV3?.propertyInfo?.slug : propertyDetailsV3?.propertyInfo?.alternateSlug) || ''
  const pageUrlCompound = `/project/${params?.slug}/${compoundDetails?.data?.projectInfo?.slug}`
  const getBreadcrumbForSchema = () => {
    const breadCrumbArray = Array.isArray(propertyDetailsV3?.propertyInfo?.breadCrumbs)
      ? propertyDetailsV3.propertyInfo.breadCrumbs.map((breadcrumb) => ({
          name: breadcrumb.title,
          item: `${lang === 'en' ? '/en' : ''}/${breadcrumb.url}`,
        }))
      : []
    
    return [
      { name: t('schema.Homepage'), item: lang == 'en' ? '/en' : '' },
      ...breadCrumbArray
    ]
  }
  const langCheck = lang == 'en' ? '/en' : ''
  return (
    <>
      {router.pathname == '/' && SchemaMarkup('', '', '', '', t, lang, { schemaType: 'Home' })}
      {router.pathname == '/about' &&
        SchemaMarkup('/about', '/about', '/about', '/about', t, lang, { schemaType: 'Home' })}
      {router.pathname == '/[slug]' &&
        SchemaMarkup(
          `/${metaData?.canonical_tag || metaData?.slug}${queryString}`,
          `/${metaData?.alternate_ar || ArabicLangCheck}${queryString}`,
          `/${metaData?.alternate_en || EnglishLangCheck}${queryString}`,
          `/${metaData?.x_default || ArabicLangCheck}${queryString}`,
          t,
          lang,
          {
            breadcrumbsList: ( (Array.isArray(breadcrumbsLinks) && breadcrumbsLinks.length > 1)
              ? breadcrumbsLinks.map((breadcrumb, index) => {
                if (index == 0) return { name: t('schema.name'), item: lang == 'en' ? '/en' : '' }
                return {
                  name: breadcrumb.title,
                  item: `${
                    index + 1 == breadcrumbsLinks.length
                      ? `${langCheck}/${params.slug}`
                      : breadcrumb.url
                  }`,
                }
                }).slice(0, -1) 
              : [{ name: t('schema.Homepage'), item: lang == 'en' ? '/en' : '' },{ name: params.slug, item: `${lang == 'en' ? '/en' : ''}/${params.slug}` }] ),
            schemaType: 'BreadcrumbList',
          },
        )}
      {router.pathname == '/project/[slug]/[projectId]' &&
        SchemaMarkup(
          pageUrlCompound,
          pageUrlCompound,
          pageUrlCompound,
          pageUrlCompound,
          t,
          lang,
          {
            breadcrumbsList: [
              { name: t('schema.name'), item: lang == 'en' ? '/en' : '' },
              {
                name: compoundBreadcrumbDetails?.title,
                item: lang == 'en' ? `/en/${compoundBreadcrumbDetails?.slug}` : `/${compoundBreadcrumbDetails?.slug}`,
              },
              // {name: compoundDetails?.data?.projectInfo?.title, item: lang == 'en' ? `/en${pageUrl}` : pageUrl },
            ],
            propertyDetails: {
              ...compoundDetails.data,
              propertyInfo: compoundDetails?.data?.projectInfo,
              propertyOwner: { phone: compoundDetails?.data?.contactDetails?.phone },
            },
            propertyImage: `${getAssetsBaseURL()}/compound/${compoundDetails?.data?.id}/images/${encodeURI(
              galleryDetails?.images?.data[0]?.content,
            )}/quality=60`,
            schemaType: 'DetailPage',
          }
        )}
      {router.pathname == '/property/[slug]/[slug-id]' &&
        propertyDetailsV3 &&
        Object.keys(propertyDetailsV3).length > 0 &&
        ![301, 404].includes(propertyDetailsV3?.statusCode) &&
        SchemaMarkup(
          `/property/${params.slug}/${lang === 'en' ? englishSlugProperty : arabicSlugProperty}`,
          `/property/${params.slug}/${arabicSlugProperty}`,
          `/property/${params.slug}/${englishSlugProperty}`,
          `/property/${params.slug}/${englishSlugProperty}`,
          t,
          lang,
          {
            breadcrumbsList: getBreadcrumbForSchema(),
            propertyDetails: propertyDetailsV3,
            propertyImage:
              getAssetsBaseURL() +
              '/properties/' +
              propertyDetailsV3.id +
              '/images/' +
              galleryDetails?.images?.data[0]?.content +
              '/quality=50,w=480',
            schemaType: 'DetailPage',
          }
        )}
    </>
  )
}

export default SchemaMarkupComponent