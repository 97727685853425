import React, { useEffect, useState } from "react";
import IconoGraphy from "@/components/Common/IconoGraphy";
import css from './toastMessage.module.scss'
import { toastConst } from "@/utils/constant";

const ToastMessage=(props)=> {
    const {onClose=null, toast, text, iconClass, extraClass=null, setToast=null, positionClass=null, type} = props
    const onCloseHandler =()=>{
        onClose && onClose()
        setToast && setToast(false)
    }
    const [toastIn,setToastin] = useState(null)
    useEffect(()=>{
        const timer = setTimeout(()=>{
            setToast&& setToast(false)
         },4500)
        if (toast === true){
            setToastin(true)
            setTimeout(()=>{
                setToastin(false)
            },3500)
        }
        return()=>{
            clearTimeout(timer)
        }
    },[toast])

    const getIconType = ()=>{
        switch(type){
            case toastConst.SUCCESS:  return <IconoGraphy iconClass='icon-toast-success' fontSize='f_16' cssStyle={{color:'#048848'}} />;
            case toastConst.INFO:  return <IconoGraphy iconClass='icon-toast-info' fontSize='f_16' cssStyle={{color:'#276EF1'}} />;
            case toastConst.ERROR:  return <IconoGraphy iconClass='icon-toast-error' fontSize='f_16' cssStyle={{color:'#E11900'}} />;
            case toastConst.WARNING:  return <IconoGraphy iconClass='icon-toast-warning' fontSize='f_16' cssStyle={{color:'#FFC043'}} />;
            default: return <IconoGraphy iconClass={`icon-${iconClass}`} iconColor='color_black' fontSize='f_16'/>
        }
    }

    const getInToastClass = toastIn === false ? css.out : ''
    return(
        <div className={`${css.toastMessage} ${type && css.newToastMessage} ${css[type]} ${toastIn? css.in : getInToastClass} ${extraClass && css[extraClass[0]]} ${positionClass && css[positionClass[0]]}`}>
            {getIconType()}
            <p>{text}</p>
            <span className={css.close} onClick={onCloseHandler}>
                <IconoGraphy iconClass='icon-close' fontSize='f_12'/>
            </span>
        </div>
    )
}
export default ToastMessage