import React from 'react'
import styles from './style.module.scss'

function AuthenticatorLoaderPopup({t}) {

  return (
    <div className={styles.authenticatorLoaderPopupContainer}>
        <div className={styles.authenticatorLoaderPopupWrapper} >
        <div className={styles.authenticatorLoaderPopupWrap}>
            <p>{t('common.AUTHENTICATING')}</p>
            <span className={styles.authenticatorLoader}></span>
        </div>
        </div>
    </div>
  )
}

export default AuthenticatorLoaderPopup
