import AutoSearch from '@/components/Common/HomeSearchNew/AutoSearch'
import { debounceFunction, pushDataLayerEventHandler } from '@/utils/utils'
import { useAppContext } from 'context/AppContext'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isDesktop } from 'react-device-detect'
import ReactHtmlParser from 'react-html-parser'
import { getAutoSuggestData } from 'services/cityOptionsService'
import { getParamsFromQuery } from 'services/queryString'
import Styles from './HomeSearch.module.scss'

const HomeSearch = (props) => {
  const { dispatch, state } = useAppContext()
  const { searchReducer } = state
  const { searchLoader, setSearchLoader, onSearch,isMobileSSR=false } = props
  const { lang } = useTranslation('translations')
  const [searchProperties, setSearchProperties] = useState(null)
  const [propertySearchResult, setPropertySearchResult] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const [transactionType, setTransactionType] = useState('sale')
  const [wrapperOpen, setWrapperOpen] = useState(false)
  const [searchCities, setSearchCities] = useState({})
  const [citiesData, setCitiesData] = useState([])
  const [searchableModalVisible, isSearchableModalVisible] = useState(false)
  const [isDesk, setIsDesk] = useState(false)
  const router = useRouter()
  const { query } = router
  const routerParams = getParamsFromQuery({ ...query })
  const prevInputVal = useRef()
  const destinationType = routerParams?.countryId === 1 ? 'domestic' : 'international';

  useEffect(() => {
    setIsDesk(isDesktop)
  }, [isDesktop])

  useEffect(() => {
    setPropertySearchResult(true)
  }, [transactionType])

  useEffect(() => {
    prevInputVal.current = inputValue
  }, [inputValue])
  useEffect(() => {
    dispatch({ type: 'UPDATE_PROPERTYFOR', payload: 'sale' })
  }, [])

  useEffect(() => {
    if (searchReducer?.isHomeStickyInputClicked) {
      dispatch({ type: 'IS_HOME_MSITE_OPEN', payload: true })
    }
  }, [searchReducer?.isHomeStickyInputClicked])

  const changeType = (type) => {
    dispatch({ type: 'UPDATE_PROPERTYFOR', payload: type })
    setTransactionType(type)

    if (type == 'sale') {
      setTransactionType('sale')
      handlePropertyIdChange(null, 'sale')
    } else {
      setTransactionType('rent')
      handlePropertyIdChange(null, 'rent')
    }
  }

  const handleCityChange = () => {
    localStorage.setItem(
      'zonefilterClick',
      JSON.stringify({
        zonefilterClick: !isDesk ? true : false,
        isMapView: false,
      }),
    )
    if (localStorage.getItem('scrollPosition')) {
      localStorage.setItem('scrollPosition', 0)
    }
  }

  const handlePropertyIdChange = useCallback(
    debounceFunction(async function (searchVal, propertyFor) {
      const reqHeaders = {
        'Content-Type': 'application/json',
        locale: lang,
        Cookie: `i18next=${lang}`,
        authorization: window.localStorage.getItem('authToken') ?? '',
      }
      // It should not call api for numeric search values
      if(searchVal && /^\d*$/.test(searchVal?.trim())){
        return;
      }
      const [topCityData] = await Promise.all([
        getAutoSuggestData(reqHeaders, {
          searchText: searchVal ? searchVal : '',
          propertyFor: propertyFor || transactionType,
          countryFlag: true,
          cityOnly:true, //It should not show country
          allCities:true // should show all cities including popular cities
        }),
      ])
      if (searchVal) {
        // added indexOf to check whether number is negative
        if (topCityData?.allCities?.length && searchVal.indexOf('-') == -1) {
          setSearchProperties(topCityData?.allCities)
          setPropertySearchResult(true)
        } else {
          setPropertySearchResult(false)
          setSearchCities({ allCities: [] })
        }
      } else {
        setCitiesData(topCityData?.allCities)
      }
    }, 400),
    [transactionType],
  )

  const handlePropertyRedirection = (slug, propertyFor, searchedVal, title) => {
    localStorage.removeItem('searchURLPD')
    const details = {
      service: propertyFor,
      search_keyword: searchedVal,
      search_result_clicked: title,
      searchType: 'property',
      property_category: routerParams?.countryId ? destinationType : '',
    }

    pushDataLayerEventHandler(window, lang, 'SearchProperty', 'Home Page', details)
    router.push(`/property/${propertyFor}/${slug}`)
  }

  const noDataHandleClick = (searchedVal, type) => {
    const details = {
      service: transactionType,
      search_keyword: searchedVal,
      search_result_clicked: 'No result found',
      searchType: type,
      property_category: routerParams?.countryId ? destinationType : '',
    }

    pushDataLayerEventHandler(window, lang, 'SearchProperty', 'Home Page', details)
  }

  const toggleSearchableModal = () => {
    dispatch({ type: 'IS_HOME_MSITE_OPEN', payload: !searchableModalVisible })
    dispatch({ type: 'IS_HOME_STICKY_INPUT_CLICKED', payload: !searchableModalVisible })
    isSearchableModalVisible(!searchableModalVisible)
  }

  useEffect(() => {
    if (searchableModalVisible) {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
    } else {
      document.body.style.overflow = 'initial'
      document.body.style.position = ''
    }
  }, [searchableModalVisible])

  return (
    /*///Start OF videoBanner */
    <>
      <div className={Styles.videoBanner} onClick={() => dispatch({ type: 'SET_IS_OUTSIDE_CLICK', payload: true })}>
        <div className={Styles.homBannerWrapper}>
          <Image priority={true} layout='fill' src={props?.bannerData?.homeBanner} alt='home-banner-image' />
        </div>
        <div
          className={`globalContainer ${Styles.containerInner} ${
            wrapperOpen && isDesk ? Styles.containerInnerMargin : ''
          }`}
        >
          {props?.mainHeading && <div>{ReactHtmlParser(props?.mainHeading)}</div>}
          <div className={`${Styles.searchWidget}`}>
            <div className={`${Styles.searchWidgetDropdown}`}>
              <AutoSearch
                cities={citiesData || []}
                inputValue={inputValue}
                setInputValue={setInputValue}
                wrapperOpen={wrapperOpen}
                setWrapperOpen={setWrapperOpen}
                searchCities={searchCities}
                setSearchCities={setSearchCities}
                handleCityClick={handleCityChange}
                searchProperties={searchProperties}
                handlePropertyIdChange={handlePropertyIdChange}
                propertySearchResult={propertySearchResult}
                handlePropertyRedirection={handlePropertyRedirection}
                noDataHandleClick={noDataHandleClick}
                toggleSearchableModal={toggleSearchableModal}
                searchableModalVisible={searchableModalVisible}
                pageName='HomePage'
                changeType={changeType}
                searchLoader={searchLoader}
                setSearchLoader={setSearchLoader}
                onSearch={(citySelected,districtAndPropertyTypeObject) => onSearch(citySelected, transactionType,districtAndPropertyTypeObject)}
                isMobileSSR={isMobileSSR}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeSearch
