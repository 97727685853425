import React, { useEffect, useState } from 'react'
import UCookies from 'universal-cookie'
import Layout from '@/components/Common/Layout/Layout'
import Home from '@/components/Home/Home'
import { getHeaderFooterLinks } from 'services/footerService'
import SchemaMarkupComponent from '@/components/Common/SchemaMarkupComponent'
import { checkStaticURL } from 'services/cmsService'
import { getAbCookie, getDeviceType } from '@/utils/utils'
import Head from 'next/head'
import { useAppContext } from 'context/AppContext'
import {setTag as sentrySetTag} from '@sentry/nextjs'
import { useRouter } from 'next/router'
import Styles from '../components/Home/Home.module.scss'
import useTranslation from 'next-translate/useTranslation'
import { setCacheHeaders } from 'services/detailPagesCache'

const App = (props) => {
  const router = useRouter()
  const { menuHeader, homeFooter, locale, cmsData, isUIinitiated, mobileSSR, abTestVariant } = props
  const { dispatch } = useAppContext();
  const {t, lang} = useTranslation('translations')
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };  
  useEffect(() => {
    dispatch({ type: 'SET_AB_TESTING_PDP', payload: abTestVariant })
  },[])

  useEffect(()=>{
    sentrySetTag('page_type', 'Home');
    sentrySetTag('device_type',getDeviceType())
  },[router])

  return (
    <>
      <Head>
        <link rel='preconnect' href={process.env.NEXT_PUBLIC_CMS_STRAPI_BASE_URL}></link>
      </Head>
      <SchemaMarkupComponent {...props} />
      {!mobileSSR ? 
        <Head>
          <link rel='preload' href={cmsData?.body_content?.banner?.imageUrl} as='image' />
        </Head>
      : ''}
      <Layout
        headerCities={menuHeader || {}}
        footerLinks={homeFooter || {}}
        currentPage='home'
        locale={locale}
        wrapperClass={'footerHomePage'}
        pageType='Home'
        firstImageURL={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/wasalt-logo${lang == 'en' ? '' : '-ar'}.svg?v1`}
      >
        {isUIinitiated && (
          <Home
            locale={locale}
            cmsData={cmsData}
            isMobileSSR={mobileSSR}
          />
        )}
      {cmsData?.body_content?.para && <div className='globalContainer'>
        <div className={Styles.home_about_box}>
          <div className={`${Styles.home_about} ${isExpanded ? Styles.expend : ''}`}>
            <div dangerouslySetInnerHTML={{ __html: cmsData?.body_content?.para?.replaceAll('<img src=\"', `<img src=\"${process.env.NEXT_PUBLIC_CMS_STRAPI_BASE_URL_API}`) }} />
          </div>
          {cmsData?.body_content?.para?.length > 490 && <button className={Styles.seeMoreButton} onClick={toggleExpand}>{isExpanded ? t('common.Read_less') : t('common.Read_more')}</button>}
         </div>
      </div>}
         
      </Layout>
    </>
  )
}

export default App

export async function getServerSideProps(context) {
  const mobileSSR = Boolean(context?.req?.headers['user-agent'].match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i), )
  const { locale } = context
  setCacheHeaders(context, process.env.NEXT_PUBLIC_DETAILPAGES_CACHE_MAXAGE)
  const uCookies = new UCookies(context.req.headers.cookie)
  try {
    const URL = checkStaticURL('/homepages', 'home-page', locale)
    const [cmsResponse, headerFooter] = await Promise.all([
      fetch(URL),
      getHeaderFooterLinks(locale)
    ])
    const cmsData = await cmsResponse?.json()
    return {
      props: {
        cmsData,
        menuHeader: headerFooter?.navigationLinks || {},
        homeFooter: headerFooter?.footerLinks || {},
        locale,
        mobileSSR,
        abTestVariant: getAbCookie(true,uCookies),
      },
    }
  } catch (e) {
    return { notFound: true }
  }
}
